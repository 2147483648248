var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('grid-view',{attrs:{"title":_vm.title,"filter":"","create":"","create-title":"Добавить пользователя","url-create":_vm.createUrl,"url-update":_vm.updateUrl,"store-module":"user","extra-filters":_vm.extraFilters,"model-fields":_vm.modelFields,"headers":_vm.headers,"format":_vm.formatItems},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"items":[{
                        text: 'Нет',
                        value: false
                    },{
                        text: 'Да',
                        value: true
                    }],"label":"Активность","clearable":""},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"new-email","label":"ФИО","type":"text","color":"purple darken-2"},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1),(_vm.queryRole === 'teacher')?_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.filterSubjectValues,"clearable":"","label":"Предмет"},model:{value:(filter.subject_name),callback:function ($$v) {_vm.$set(filter, "subject_name", $$v)},expression:"filter.subject_name"}})],1):_vm._e(),(['student', 'teacher'].includes(_vm.queryRole) )?_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"items":_vm.filterGroupValues,"clearable":"","label":"Класс"},model:{value:(filter.group_name),callback:function ($$v) {_vm.$set(filter, "group_name", $$v)},expression:"filter.group_name"}})],1):_vm._e(),(_vm.queryRole === 'student')?_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Параллель","type":"number","color":"purple darken-2"},model:{value:(filter.grade),callback:function ($$v) {_vm.$set(filter, "grade", $$v)},expression:"filter.grade"}})],1):_vm._e()],1)]}}],null,false,1883813940)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }